
.container {
    background-color: #000000;
    color: #FFFFFF;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Arial', sans-serif;
  }
  
  .logo {
    margin-bottom: 20px;
  }
  
  .logo_bg {
    margin-bottom: 20px;
    background-color: white;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 0 0 1px #c1c1c1;
  }
  
  .logoImage {
    width: 100px;
    height: auto;
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 30px;
  }
  
  .subscribeButton {
    background-color: #00FF00;
    color: #FFFFFF;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    padding: 15px 40px;
    cursor: pointer;
    margin-bottom: 15px;
    box-shadow: 0 0 15px 5px rgba(0, 255, 0, 0.9);
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .costText {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .termsLink {
    color: #00FF00;
    text-decoration: none;
    font-size: 14px;
  }
  