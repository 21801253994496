@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Signika+Negative:wght@300..700&display=swap');


.container{
    font-family: "Arial, sans-serif";
    line-height: "1.6";
    color: #333;
    padding: 2rem;
    /* max-width: 800px; */
    margin: 0 auto;
    background-color: #fff;
    /* color: white; */
    font-size: 1.25rem;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 8px;
    font-family: Signika Negative, sans-serif;
  }
  .header{
    text-align: center;
    margin-bottom: 20px;
    color: #2c3e50;
    font-family: Signika Negative, sans-serif;
  }
  .lastUpdated{
    text-align: center;
    font-style: italic;
    margin-bottom: 30px;
  }
  .section {
    margin-bottom: 20px;
  }
  .footer {
    margin-top: 30px;
    text-align: center;
    font-size: 1.25rem;
    color: #555;
  }

  .header_name{
    color: white;
    text-align: center;
    font-family: Signika Negative, sans-serif;
  }